/* Adjust the toolbar on small screens */
.fc {
  font-size: 1rem; /* General font size for the calendar */
}

.fc .fc-timegrid-event {
  font-size: 1rem; /* Font size for events in the time grid view */
  padding: 4px 0px 0px 4px;
}

.fc .fc-timegrid-slot {
  height: 2em;
  padding: 0.5em;
}

.fc .fc-event-time {
  font-size: 1.1rem; /* Font size for events in the time grid view */
}

@media (max-width: 450px) {
  .fc-toolbar-title {
    font-size: 1.2rem !important; /* Example size, adjust as needed */
  }
}
